html,
body {
  margin: 0;
  padding: 0;
}

#root {
  width: 100vw;
  height: 100vh;
  margin: 0;
  overflow: hidden;
}

/* Main body */
.main {
  margin: 0;
  padding: 0 8vw;
  width: 85vw;
  height: 100vh !important;
  display: flex;
  flex-direction: row;
  position: relative;
  z-index: 1;
  /* Set the main content to be above the circle */
  margin-left: auto;
  margin-right: auto;
  opacity: 0;
  /* Start with the main content being invisible */
  transition: opacity 2s ease;
  /* Transition for the fade-in effect */
}

.main.fade-in {
  opacity: 1;
}


/* Splash screen */
.splash-screen {
  transition: opacity 1s ease;
  opacity: 1;
}

.splash-screen.fade-out {
  opacity: 0;
}


/* light circle attached to pointer */
.circle {
  z-index: -1;
  width: 400px;
  height: 400px;
  background: rgba(96, 103, 102, 0.267);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  /* this centers the circle on the pointer */
  pointer-events: none;
  -webkit-backdrop-filter: blur(100px);
  /* For Safari */
  backdrop-filter: blur(100px);
  /* For modern browsers */
  filter: blur(100px);
  /* Fallback blur for other browsers */
  opacity: 50%;
}


/* hero */
.hero {
  padding: 6vh 0 7vh 5em;
  width: 50%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-left: 5rem;
}

/* profile photo */
.hero-circle {
  line-height: 0;
  /* remove line-height */
  display: inline-block;
  /* circle wraps image */
  margin: 5px;
  border: 4px solid #02bbf8;
  border-radius: 50%;
  /* relative value */
  /*box-shadow: 0px 0px 5px rgba(0,0,0,0.4);*/
  transition: linear 0.25s;
  height: 128px;
  width: 128px;
}

.hero-circle img {
  border-radius: 50%;
  /* relative value for adjustable image size */
}

.hero-circle:hover {
  transition: ease-out 0.2s;
  border: 4px solid rgba(0, 0, 0, 0.2);
  -webkit-transition: ease-out 0.2s;
}

a.hero-circle {
  color: transparent;
  /* IE fix: removes blue border */
}

.intro h1 {
  font-size: 48px;
  color: #ffffff;
  margin-bottom: 0px;
}

.intro h4 {
  margin-bottom: 20px;
  color: #ffffff;
  opacity: 0.8;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  color: #ffffff;
}

.intro p {
  max-width: 80%;
  font-size: 18 px;
  color: #94a3b8;
}

/* menu */
.menu {
  width: 100%;
  height: 25vh;
  display: flex;
  flex-direction: column;
}

.menu a {
  color: #ffffff;
  opacity: 0.8;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.65);
  font-weight: 600;
  letter-spacing: 1px;
  text-decoration: none;
  transition: 0.3s all;
}

.menu a:hover {
  padding-left: 0.5em;
  letter-spacing: 3px;
  font-weight: 900;
  color: #02bbf8;
}

.menu.active::before {
  content: '';
  display: block;
  height: .65em;
  width: .65em;
  background: currentColor;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: -.65em;
  /* size of dot */
  transform: translateY(-50%);
  /* vertical alignment */
}

/* Social */
.social {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 25vh;
  gap: 7px;
}

.social a .r {
  position: relative;
  text-align: center;
  text-decoration: none;
  padding-bottom: 5px;
  color: rgba(255, 255, 255, 0.65);
  width: 140px;
  transition: 0.3s all ease-in-out;
}

.social a img {
  filter: invert();
  padding: 0 0;
  opacity: 0.3;
  width: 30px;
  transition: all 0.3s ease-in-out;
}

.social a img:hover {
  transform: scale(1.1);
  opacity: 0.5;
}

.social a .i,
.social a .g,
.social a .l {
  width: 34px;
}

/* Content */
.content {
  width: 50%;
  height: 100vh - 6vh;
  /* calc(100vh - 8vh) *
  /* Adjust the height based on the sum of the padding values. */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
  -ms-overflow-style: none;
  /* Internet Explorer and Edge */
  scrollbar-width: none;
  /* Firefox */
  scroll-behavior: smooth;
  padding-right: 5rem;
}

.content .end {
  padding: 5em 1em 0;
  font-size: 16px;
  opacity: 0.85;
}

.content .end a {
  color: rgba(94, 234, 212, 0.85);
}

.content::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and Opera */
}

.content .about {
  padding-top: 5vh;
  width: 90%;
}

.content .about p {
  font-size: 18px;
  opacity: 0.6;
  color: #d4eeee;
}

.content .about p b {
  color: rgba(94, 234, 212, 1);
}

/* About */
.about {
  padding: 5em 0 0em;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.about-path .title {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 0;
}

.about-path .title2 {
  color: rgba(255, 255, 255, 0.8);
  margin-top: 2em;
}

.about-item {
  margin: 1.5em 0;
  align-items: center;
  border-radius: 10px;
  display: flex;
  overflow: hidden;
  width: 90%;
  border-color: rgba(255, 255, 255, 0);
  border-style: solid;
  border-width: 2px;
  padding: 1em;
  height: auto;
  transition: all 0.35s ease;
}

.about-item:hover {
  background-color: rgba(255, 255, 255, 0.025);
  backdrop-filter: blur(20px);
}

.about-item h6 {
  opacity: 0.6;
  margin: 0;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.about-item-intro {
  align-self: flex-start;
  color: #fff;
  padding-top: 5px;
  padding-left: 10px;
  width: 30%;
}

.about-item-details {
  padding: 0px 20px;
  position: relative;
  width: 100%;
}

.about-item-details h4 {
  padding: 0;
  margin: 0 0 0.15em;
  font-weight: 700;
  opacity: 0.8;
  font-size: 18px;
  transition: color 0.3s ease;
}

.about-item:hover .lesson-details h4 {
  color: white;
  /* Or any other color you prefer */
}

.about-item-details h6 {
  opacity: 0.6;
}

.about-item-details p {
  opacity: 0.6;
  font-size: 14px;
}

/* ============================================
   Education Section CSS
   - Clean alignment
   - Smooth hover effects
   - Modern and tech-inspired style
   ============================================ */

.education {
  /* This the Education Text */
  padding: 5em 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: 900;
  color: #e0e0e0;
  /* Light text for dark background */
}

.school-item {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  /* Stack content for better alignment */
  background: rgba(255, 255, 255, 0.05);
  /* Subtle background color */
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* Transparent border */
  border-radius: 12px;
  /* Smooth corners */
  overflow: hidden;
  padding: 1.5em;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.school-item:hover {
  background: rgba(6, 127, 140, 0.1);
  /* Subtle accent hover color */
  box-shadow: 0px 6px 20px #02bbf8;
  transform: translateY(-5px);
  /* Lift effect */
}

.school-item h6 {
  opacity: 0.7;
  margin: 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 12px;
  color: #a0a0a0;
  /* Subtle secondary text color */
}

.school-item-intro {
  align-self: flex-start;
  color: #02bbf8;
  /* Neon green for tech-inspired dates */
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 16px;
}

.school-item-details {
  padding: 10px 20px;
  width: 100%;
}

.school-item-details h4 {
  margin: 0 0 0.25em;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  /* Bright white for focus */
  transition: color 0.3s ease;
}

.school-item:hover .school-item-details h4 {
  color: #02bbf8;
  /* Highlight text on hover */
}

.school-item-details h6 {
  opacity: 0.8;
  font-size: 14px;
  color: #cccccc;
}

.school-item-details p {
  opacity: 0.7;
  font-size: 12px;
  color: #b3b3b3;
  line-height: 1.5;
}



/* ============================================
   Experience Section CSS
   - Clean layout
   - Dynamic hover effects
   - Tech-inspired style
   ============================================ */

.experience {
  padding: 2em 0 2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #e0e0e0;
  /* Light text for contrast */
  font-weight: 900;
}

/* Experience Section Title */
.experience-title {
  font-weight: 900;
  /* Ensures boldness */
  color: #e0e0e0;
  /* White color */
}

.experience-path .title {
  color: rgba(255, 255, 255, 0.8);
  font-size: 20px;
  margin-top: 0;

}

.experience-path .title2 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 12px;
  margin-top: 2em;
}

.experience-item {
  margin: 1.5em 0;
  display: flex;
  flex-direction: column;
  /* Stack content for clean alignment */
  background: rgba(255, 255, 255, 0.05);
  /* Subtle background for contrast */
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* Slight border for separation */
  border-radius: 12px;
  overflow: hidden;
  padding: 1.5em;
  width: 90%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* Add depth */
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.experience-item:hover {
  background: rgba(6, 127, 140, 0.1);
  /* Subtle green accent for hover */
  box-shadow: 0px 6px 20px #02bbf8;
  /* Enhanced hover shadow */
  transform: translateY(-5px);
  /* Lift effect for hover */
}

.experience-item h6 {
  opacity: 0.7;
  margin: 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 14px;
  color: #a0a0a0;
  /* Subtle secondary text color */
}

.experience-item-intro {
  align-self: flex-start;
  color: #02bbf8;
  /* Neon green for a tech-inspired highlight */
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 16px;
}

.experience-item-details {
  padding: 10px 20px;
  width: 100%;
}

.experience-item-details h4 {
  margin: 0 0 0.25em;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  /* Bright white for focus */
  transition: color 0.3s ease;
}

.experience-item:hover .experience-item-details h4 {
  color: #02bbf8;
  /* Highlight text on hover */
}

.experience-item-details h6 {
  opacity: 0.8;
  font-size: 16px;
  color: #cccccc;
}

.experience-item-details p {
  opacity: 0.7;
  font-size: 14px;
  color: #b3b3b3;
  line-height: 1.5;
}

.skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  /* More spacing between skills */
  margin-top: 10px;
}

.skill-button {
  background: rgba(116, 145, 140, 0.1);
  border: 1px solid rgba(9, 10, 10, 0.2);
  /* Border for separation */
  backdrop-filter: blur(20px);
  display: inline-block;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 15px;
  text-decoration: none;
  color: #ffffff;
  /* Neon green for futuristic look */
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.skill-button:hover {
  background: rgba(94, 234, 212, 0.1);
  /* Slightly darker hover effect */
  color: white;
  transform: scale(1.05);
  /* Subtle zoom effect */
}

/* ============================================
   Project Item CSS
   - Unique styling for project items
   ============================================ */
.project-item {
  margin: 2em 0;
  display: flex;
  flex-direction: column;
  /* Stack content for clean alignment */
  background: rgba(255, 255, 255, 0.05);
  /* Subtle background for contrast */
  border: 1px solid rgba(255, 255, 255, 0.1);
  /* Slight border for separation */
  border-radius: 12px;
  overflow: hidden;
  padding: 1.5em;
  
  /* Adjust width as needed */
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  /* Add depth */
  transition: transform 0.3s ease, background 0.3s ease, box-shadow 0.3s ease;
}

.project-item:hover {
  background: rgba(6, 127, 140, 0.1);
  /* Subtle green accent for hover */
  box-shadow: 0px 6px 20px #02bbf8;
  /* Enhanced hover shadow */
  transform: translateY(-5px);
  /* Lift effect for hover */
}

.project-item h6 {
  opacity: 0.7;
  margin: 0;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  font-size: 14px;
  color: #a0a0a0;
  /* Subtle secondary text color */
}

.project-item-intro {
  align-self: flex-start;
  color: #02bbf8;
  /* Neon green for a tech-inspired highlight */
  font-weight: 600;
  padding-bottom: 10px;
  font-size: 16px;
}

.project-item-details {
  padding: 10px 20px;
  width: 100%;
}

.project-item-details h4 {
  margin: 0 0 0.25em;
  font-weight: 700;
  font-size: 20px;
  color: #ffffff;
  /* Bright white for focus */
  transition: color 0.3s ease;
}

.project-item:hover .project-item-details h4 {
  color: #02bbf8;
  /* Highlight text on hover */
}

.project-item-details h6 {
  opacity: 0.8;
  font-size: 16px;
  color: #cccccc;
}

.project-item-details p {
  opacity: 0.7;
  font-size: 14px;
  color: #b3b3b3;
  line-height: 1.5;
}

/* Skill badges for projects */
.project-item .skills-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 8px;
  /* More spacing between skills */
  margin-top: 10px;
}

.project-item .skill-button {
  background: rgba(116, 145, 140, 0.1);
  border: 1px solid rgba(9, 10, 10, 0.2);
  /* Border for separation */
  backdrop-filter: blur(20px);
  display: inline-block;
  padding: 5px 15px;
  margin: 5px 0;
  border-radius: 15px;
  text-decoration: none;
  color: #ffffff;
  /* Neon green for futuristic look */
  text-align: center;
  font-size: 13px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.project-item .skill-button:hover {
  background: rgba(94, 234, 212, 0.1);
  /* Slightly darker hover effect */
  color: white;
  transform: scale(1.05);
  /* Subtle zoom effect */
}

.icon-intro {
  justify-content: center;
  align-self: flex-start;
  color: #ffffff;
  padding-top: 5px;
  padding-left: 10px;
  width: 30%;
}

.icon-intro img {
  width: 60%;
  opacity: 0.6;
}

.icon-intro .blog {
  width: 80%;
  margin-left: -10px;
}

.link {
  padding-left: 0.3em;
  padding-bottom: 0.5em;
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.link a {
  color: #ffffff;
}

.link a:hover {
  color: #ffffff;
}

.experience-item:hover .project-details h4 {
  color: #02bbf8;
}


/* responsiveness */
@media (max-width: 1300px) {
  .hero {
    padding-left: 2em;
  }

  .content {
    padding-right: 2em;
  }

  .main {
    padding: 0;
    width: 100vw
  }

  .intro p {
    max-width: 90% !important;
  }
}

@media (max-width: 800px) {
  #root {
    height: 100%;
  }

  .main {
    padding: 0;
    height: 100% !important;
    width: 100vw;
    display: flex;
    flex-direction: column;
    position: relative;
    align-items: center;
  }

  .circle {
    opacity: 0%;
  }

  .resume {
    width: 150px;
  }

  .github {
    width: 150px;
  }

  .lesson {
    flex-direction: column;
  }

  .school-item {
    flex-direction: column;
  }

  .school-item-intro {
    width: 100%;
    padding: 0;
    padding-bottom: 1em;
  }

  .lesson-intro {
    width: 100%;
    padding: 0;
    padding-bottom: 1em;
  }

  .icon-intro {
    padding: 0;
    padding-bottom: 1em;
  }

  .hero {
    padding: 1vh 0;
    width: 90% !important;
    height: auto !important;
    flex-direction: column;
    justify-content: center;
  }

  .intro p {
    max-width: 90%;
    font-size: 18 px;
    color: #94a3b8;
  }

  .content {
    align-items: center;
    width: 90% !important;
    height: 100% !important;
    /* Adjust the height based on the sum of the padding values. */
    padding: 3vh 0 0;
    overflow-y: unset;
  }

  .content .about {
    padding-top: 5vh;
    width: 100%;
  }
}